// _fonts.scss
@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-Light.eot');
    src: url('../assets/fonts/SFUIText-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-Light.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-Light.woff') format('woff'),
        url('../assets/fonts/SFUIText-Light.svg#SFUIText-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-MediumItalic.eot');
    src: url('../assets/fonts/SFUIText-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-MediumItalic.woff') format('woff'),
        url('../assets/fonts/SFUIText-MediumItalic.svg#SFUIText-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-SemiboldItalic.eot');
    src: url('../assets/fonts/SFUIText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-SemiboldItalic.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-SemiboldItalic.woff') format('woff'),
        url('../assets/fonts/SFUIText-SemiboldItalic.svg#SFUIText-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-Semibold.eot');
    src: url('../assets/fonts/SFUIText-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-Semibold.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-Semibold.woff') format('woff'),
        url('../assets/fonts/SFUIText-Semibold.svg#SFUIText-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-Regular.eot');
    src: url('../assets/fonts/SFUIText-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-Regular.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-Regular.woff') format('woff'),
        url('../assets/fonts/SFUIText-Regular.svg#SFUIText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-Heavy.eot');
    src: url('../assets/fonts/SFUIText-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-Heavy.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-Heavy.woff') format('woff'),
        url('../assets/fonts/SFUIText-Heavy.svg#SFUIText-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-HeavyItalic.eot');
    src: url('../assets/fonts/SFUIText-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-HeavyItalic.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-HeavyItalic.woff') format('woff'),
        url('../assets/fonts/SFUIText-HeavyItalic.svg#SFUIText-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-RegularItalic.eot');
    src: url('../assets/fonts/SFUIText-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-RegularItalic.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-RegularItalic.woff') format('woff'),
        url('../assets/fonts/SFUIText-RegularItalic.svg#SFUIText-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-BoldItalic.eot');
    src: url('../assets/fonts/SFUIText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-BoldItalic.woff') format('woff'),
        url('../assets/fonts/SFUIText-BoldItalic.svg#SFUIText-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-Bold.eot');
    src: url('../assets/fonts/SFUIText-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-Bold.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-Bold.woff') format('woff'),
        url('../assets/fonts/SFUIText-Bold.svg#SFUIText-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-LightItalic.eot');
    src: url('../assets/fonts/SFUIText-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-LightItalic.woff') format('woff'),
        url('../assets/fonts/SFUIText-LightItalic.svg#SFUIText-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Text';
    src: url('../assets/fonts/SFUIText-Medium.eot');
    src: url('../assets/fonts/SFUIText-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/SFUIText-Medium.woff2') format('woff2'),
        url('../assets/fonts/SFUIText-Medium.woff') format('woff'),
        url('../assets/fonts/SFUIText-Medium.svg#SFUIText-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}