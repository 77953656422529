.modal_lg {
  width: 100%;
  .MuiDialog-container {
    padding-right: 21px;
  }
  .model-dialog {
    // overflow-y: auto;
  }
  .MuiDialog-paperWidthSm {
    @media (min-width: 800px) {
      min-width: 737px;
    }
  }
  .MuiDialog-paper {
    overflow: visible;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    .diagraammmm {
      background: #424959;
      position: absolute;
      right: -20px;
      top: -20px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      line-height: 45px;
      padding: 0;

      .MuiSvgIcon-root {
        fill: #fff;
      }
    }
    .MuiDialogContent-root {
      padding: 0;
      .model-header {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #e9ecef;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        gap: 20px;
        input {
          width: 100%;
        }
        button {
          min-height: 50px;
        }
      }
      .giphy-grid {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        width: 100% !important;
        & > div {
          width: 100%;
        }
      }
    }
  }
}
.hideCursor + .giphy-grid a {
  cursor: default;
}
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: #414959;
}
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
