@import url('../../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import '../theme/base.scss';

* {
    box-sizing: border-box;
    font-family: $default-font;
    color:$base-color;
    word-break: break-word;
    white-space: normal;
}
body,
html {
  margin:0;
  height: 100%; 
  font-weight: $f-regular;;
  font-size: $f16;
}
iframe {border:0;}
img {border:0; outline: 0!important;}
h1,h2,h3,h4,h5,h6 {
  color:$base-color;
  font-weight: $f-bold;
  margin: 0px;
}
h1 {
    font-size: 2rem;
    @media (max-width:767px){
      font-size: 1.75rem;
    }
}
h2{
  font-size: 1.8rem;
  @media (max-width:767px){
    font-size: 1.5rem;
  }
}
h3{
  font-size: 1.6rem;
  @media (max-width:767px){
    font-size:1.35rem;
  }
}
h4{
  font-size: 1.4rem;
  @media (max-width:767px){
    font-size:1.2rem;
  }
}
h5{
  font-size: 1.2rem;
}
h6{
  font-size: 1rem;
}
a {
  color: $primary-color;
  font-size: $f12+3;
  line-height: 1.7;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  &:focus {
    outline: none !important;
  }
  &:hover {
    text-decoration: none;
    color: $teal-color;
  }
}
p {
  font-size: $f16;
  line-height: 1.7;
  color: $base-color;
  margin: 0px;
}
ul,li {
  padding:0;
  margin: 0;
  list-style-type: $none;
}
.shadow {
    box-shadow: 0 0.1rem .7rem .1rem rgba(0, 0, 0, .15) !important;
}
button {
  outline: none !important;
  border-color: 0;
  border:0;
  cursor: pointer;
}
input, textarea {
  outline: $none; 
}
label {
  font-weight: $f-medium;
  margin-bottom: 0;
}
.btn {line-height: normal;
&:focus {
  box-shadow: none!important;
}
}
.btn-sm {min-width: 70px; word-break: keep-all;}
.btn-md {
  display: $inline-block;
  padding: 8px 10px;
  font-size: $f16;
  font-weight: 600;
  min-width: 130px;
  text-align: center; 
  height:40px;  
  @include border-radius (7px);
}
.btn-lg {
  display: $inline-block;
  padding: 10px 15px;
  font-size: $f16;
  font-weight: $f-medium;
  min-width: 156px;
  text-align: center; 
} 
.btn-primary {
  background: $secondary-color;
  border: 1px solid $secondary-color;
  color: $white-color;
  text-transform: capitalize;
  &:hover {
    background: $secondary-color;
    border: 1px solid $secondary-color;
  }
  i{
    color:$white-color;
    font-weight: $f-bold;
    margin-right: 5px;
    font-style: normal;
  }
}
.btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle{
  background: $teal-color;
  border-color: $teal-color;
  border: 1px solid $teal-color;
}
.btn-primary.disabled, .btn-primary:disabled {
  background: $grey-color;
  border-color: $grey-color;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, 
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.show > .btn-outline-primary.dropdown-toggle {
  background: $black-color1;
  border-color: $black-color1;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,.btn-primary.focus, 
.btn-primary:focus,.form-control:focus, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-primary.dropdown-toggle:focus{
  box-shadow: none !important;
}
.btn-secondary {
  background: $primary-color;
  border-color:$primary-color;
  &:hover, &:focus {
    background: $teal-color;
  }
  i{
    color:$white-color;
    font-weight: $f-bold;
    margin-right: 5px;
    font-style: normal;
  }
}
.btn-radius {
  @include border-radius (25px);
}
textarea {
  outline: $none;
  border: $none;
  &.form-control {
    min-height: 140px;
    padding: .675rem .75rem;
  }
}
.form-control {
  font-size: $f16;
  color: $inputTxt-color;
  border-color: $grey-color2;
  line-height: 1;
  display: $block;
  width: 100%;
  min-height: 50px;
  background: $white-color;
  // resize: none;
   &:focus {
    border-color: $inputTxt-color;
  }
}
input::-webkit-input-placeholder {
  color: $grey-color5;
}
input:-moz-placeholder {
  color: $grey-color5;
}
input:-ms-input-placeholder {
  color: $grey-color5;
}
textarea::-webkit-input-placeholder {
  color: $grey-color5;
}
textarea:-moz-placeholder {
  color: $grey-color5;
}
textarea::-moz-placeholder {
  color: $grey-color5;
}
textarea:-ms-input-placeholder {
  color: $grey-color5;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 48px !important;
  border-color: $grey-color2 !important;
}
.css-1pahdxg-control {
  box-shadow: 0 0 0 1px $inputTxt-color !important;
}
.form-group {
  margin-bottom: 1.5rem;
  position:relative;
  @media(max-width:767px){
    margin-bottom: 1.3rem;
  } 
}

.MuiTextField-root.form-control {
  border-color: $grey-color2;
  border-width:1px;
  border-style: solid;
  @include border-radius (4px);
  padding: 10px;
  color:$black-color;
}

.MuiInput-underline {
  &:before {
      display: $none;
  }
  &:after {
      display:$none!important;
  }
}

.float_label {display:$none;}

//Scrollbar
body::-webkit-scrollbar {
  width: .5em; 
} 
body::-webkit-scrollbar-track {
  background-color: $grey-color3;
} 
body::-webkit-scrollbar-thumb {
  background-color: #414959;  
}
// Table Scrollbar
.table-responsive::-webkit-scrollbar {
  width: 1em;
  height:.4em;
}
 
.table-responsive::-webkit-scrollbar-track {
  background-color: $grey-color3;
}
 
.table-responsive::-webkit-scrollbar-thumb {
  background-color: $grey-color4;  
}

.MuiTypography-subtitle1 {
  white-space: nowrap;
}
